<template>
  <v-app class="bk">
    <v-system-bar app dark window color="#3D1159">
      <v-spacer></v-spacer>

      <a href="https://www.lyndus.com.br/">Imprensa</a>
      <a href="https://www.lyndus.com.br">Trabalhe Conosco</a>
      <a class="last" href="https://www.lyndus.com.br">Fale Conosco</a>
    </v-system-bar>

    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="12" md="12">
          <v-card :elevation="24" class="mx-auto" max-width="400">
            <v-form v-model="recoverPassForm" ref="recoverForm">
              <v-card-text>
                <div
                  class="d-flex flex-column justify-space-between align-center"
                >
                  <v-img
                    src="@/assets/logo.jpg"
                    class="mt-2"
                    max-height="150"
                    max-width="300px"
                  ></v-img>
                </div>
                <v-alert :value="true" type="warning" outlined v-if="errors">
                  {{errors}}
                </v-alert>
                <v-list-item two-line>
                  <v-list-item-content>
                    <v-list-item-title class="headline">
                      Recuperação de senha
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    name="username"
                    type="text"
                    label="Nome de usuário"
                    v-model="username"
                    :rules="textRules"
                    placeholder="Digite o seu usuário"
                    outlined
                  ></v-text-field>
                </v-list-item>

                <v-list-item>
                  <v-text-field
                    name="cnpj"
                    type="text"
                    label="CNPJ"
                    v-model="cnpj"
                    v-mask="['##.###.###/####-##']"
                    :rules="cnpjRules"
                    placeholder="Digite o seu CNPJ"
                    outlined
                  ></v-text-field>
                </v-list-item>

                <div class="text-center w-100">
                  <v-btn :to="{ name: 'login' }" text small color="indigo">
                    retornar ao login
                  </v-btn>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  block
                  :to="{ name: 'recoverySuccess' }"
                  color="#6D59E8"
                  dark
                  large
                >
                  <v-icon left dark>mdi-send</v-icon>
                  Enviar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-footer color="#3D1159" inset dark class="py-5 px-2 text-center">
      <v-spacer></v-spacer>
      <a
        href="https://goo.gl/maps/LBbJmfUr5atJteAVA"
        target="_blank"
        class="align-center"
      >
        Lyndus Tecnologia e Pagamentos Ltda, CNPJ 37.475.731/0001-01
        <br />Rua Padre Anchieta, 2348, CEP 80.730-001, Curitiba, PR
      </a>

      <v-spacer></v-spacer>
    </v-footer>
  </v-app>
</template>

<script>
import { validarCNPJ } from "@/common/cnpj.service";
import { mask } from "vue-the-mask";

export default {
  name: "PasswordRecover",

  directives: { mask },

  data: () => ({
    username: "",
    cnpj: "",

    recoverPassForm: false,
    textRules: [
      v => !!v || "Este campo é obrigatório"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ],
    cnpjRules: [
      v => !!v || "O CNPJ é necessário",
      v => validarCNPJ(v) || "Informe um CNPJ válido."
    ]
  })
};
</script>

<style scoped>
body {
  min-height: 600px;
}
.bk {
  background-image: url("~@/assets/background-bs.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}
.v-system-bar a {
  font-family: Arial, Helvetica, sans-serif;
  display: block;
  font-size: 10px;
  color: #ffffff;
  margin: 0px 0px 10px;
  text-decoration: none;
  line-height: 10px;

  float: left;
  display: block;
  height: 8px;
  margin-top: 13px;
  margin-right: 8px;
  padding-right: 8px;
  border-right: 1px solid #ffffff;
}

.v-system-bar .last {
  border-right: none;
}

.v-system-bar .on {
  color: #ffffff;
}

.v-footer a {
  font-family: "Montserrat";
  font-size: 12px;
  color: #ffffff;
  line-height: 15px;
  text-decoration: none;
}

.v-list-item .v-list-item__subtitle, .v-list-item .v-list-item__title {
  font-size: 12px !important;
  text-transform: uppercase;
  text-align: center;
  font-family: Montserrat,sans-serif!important;
  margin-bottom: -15px;
}
</style>
